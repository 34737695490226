import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import * as Constants from "src/core/Constants/Constants";
import { useQuery } from "src/core/Hooks";
import { CorePropsInterfaces } from "src/core/Models";

export default function GtNavigationButtons({
  current,
}: CorePropsInterfaces.GtNavigationButtonsProps): JSX.Element {
  const queryParamsMap = useQuery();
  const businessUnit = queryParamsMap.get(Constants.QueryParams.unit);
  const { search } = useLocation();
  const { t } = useTranslation();
  const links = [
    {
      link: Constants.Routes.Home,
      businessUnits: [
        Constants.BusinessUnit.Audit,
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Tax,
      ],
    },
    {
      link: Constants.Routes.Question,
      businessUnits: [
        Constants.BusinessUnit.Audit,
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Tax,
      ],
    },
    {
      link: Constants.Routes.PriceAndTimeCalculation,
      businessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      link: Constants.Routes.EngagementDescription,
      businessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      link: Constants.Routes.GenerateDocuments,
      businessUnits: [
        Constants.BusinessUnit.Audit,
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Tax,
      ],
    },
  ];
  const filteredLinks = links.filter((link) =>
    link.businessUnits.includes(businessUnit as Constants.BusinessUnit)
  );

  return (
    <article className="gt-navigationButtons">
      {filteredLinks[current - 1] && (
        <NavLink to={`/${filteredLinks[current - 1].link + search}`}>
          <Button color="tertiary" variant="outlined">
            {t("General.Previous")}
          </Button>
        </NavLink>
      )}
      {filteredLinks[current + 1] && (
        <NavLink to={`/${filteredLinks[current + 1].link + search}`}>
          <Button color="tertiary" variant="contained">
            {t("General.Next")}
          </Button>
        </NavLink>
      )}
    </article>
  );
}
