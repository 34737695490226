import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import { CorePropsInterfaces } from "src/core/Models";
import * as Constants from "./../../core/Constants";

export default function GtAlertBanner({
  message,
  variant,
  withCloseButton = true,
}: CorePropsInterfaces.GtAlertBannerProps): JSX.Element {
  let alertVariant = "";
  if (
    !!variant &&
    variant === Constants.GtAlertBannerVariant.GenerateDocuments
  ) {
    alertVariant = "gt-alertBanner--generateDocumentsVariant";
  }
  if (!!variant && variant === Constants.GtAlertBannerVariant.QuestionPage) {
    alertVariant = "gt-alertBanner--questionPageVariant";
  }
  return (
    <article className={`gt-alertBanner ${alertVariant}`}>
      <section className="gt-alertBanner__left">
        <ErrorOutlineRoundedIcon className="gt-alertBanner__left-icon" />
        {message}
      </section>
      {withCloseButton && (
        <section className="gt-alertBanner__right">
          <CloseIcon />
        </section>
      )}
    </article>
  );
}
