import { CoreInterfaces } from "src/core/Models";
import * as Constants from "./../core/Constants";
import { createEmptyClient, createEmptyContactPerson } from "./question-utils";

export function getAlterServerResponse(
  serverReturn: CoreInterfaces.EngagementConfigurationReadPack,
  appState: CoreInterfaces.AppState,
  clientId: string
): CoreInterfaces.EngagementConfigurationReadPack {
  serverReturn.Id = appState.currentConfiguration.id;
  serverReturn.Version = appState.currentConfiguration.version;
  serverReturn.Description = appState.currentConfiguration.description;
  serverReturn.CrmRowId = appState.currentConfiguration.crmRowId;
  serverReturn.LastModifiedDate =
    appState.currentConfiguration.lastModifiedDate;
  serverReturn.LastAccessedTime =
    appState.currentConfiguration.lastAccessedTime;
  serverReturn.Status = Constants.ConfigurationStatus.Active;
  serverReturn.SigningStatus = Constants.SigningStatus.NotSigned;
  serverReturn.SigningDate = null;
  serverReturn.DigitalSigningDetails = null;
  serverReturn.EngagementLetterProperties = null;

  if (serverReturn.ClientId !== clientId) {
    serverReturn.Services.forEach((service) => {
      switch (service.Code) {
        case Constants.ServiceCode.GeneralInformation: {
          let questionUserInput = service.Questions.find(
            (question) =>
              question.Code === Constants.GeneralInformationQuestion.Q0018
          ).UserInput;
          questionUserInput.Answer = Constants.HelpfulConstants.PleaseSelect;
          questionUserInput.IsFilled = false;

          questionUserInput = service.Questions.find(
            (question) =>
              question.Code === Constants.GeneralInformationQuestion.Q0034
          ).UserInput;
          questionUserInput.Answer = [
            createEmptyContactPerson(true),
          ] as unknown as string;
          questionUserInput.IsFilled = false;

          questionUserInput = service.Questions.find(
            (question) =>
              question.Code === Constants.GeneralInformationQuestion.Q0036
          ).UserInput;
          questionUserInput.Answer = Constants.HelpfulConstants.PleaseSelect;
          questionUserInput.IsFilled = false;

          questionUserInput = service.Questions.find(
            (question) =>
              question.Code === Constants.GeneralInformationQuestion.QT105
          ).UserInput;
          questionUserInput.Answer = Constants.HelpfulConstants.PleaseSelect;
          questionUserInput.IsFilled = false;

          questionUserInput = service.Questions.find(
            (question) =>
              question.Code === Constants.GeneralInformationQuestion.QT145
          ).UserInput;
          questionUserInput.Answer = [
            createEmptyClient(appState, true),
          ] as unknown as string;
          questionUserInput.IsFilled = false;
          break;
        }
        case Constants.ServiceCode.AccountsReceivable: {
          const questionUserInput = service.Questions.find(
            (question) =>
              question.Code === Constants.AccountReceivableQuestion.Q0120
          ).UserInput;
          questionUserInput.Answer = Constants.HelpfulConstants.PleaseSelect;
          questionUserInput.IsFilled = false;
          break;
        }
        case Constants.ServiceCode.AccountsPayable: {
          const questionUserInput = service.Questions.find(
            (question) =>
              question.Code === Constants.AccountPayableQuestion.Q0221
          ).UserInput;
          questionUserInput.Answer = Constants.HelpfulConstants.PleaseSelect;
          questionUserInput.IsFilled = false;
          break;
        }
        case Constants.ServiceCode.CorporateCardManagement: {
          const questionUserInput = service.Questions.find(
            (question) =>
              question.Code === Constants.CorporateCardManagementQuestion.Q0311
          ).UserInput;
          questionUserInput.Answer = Constants.HelpfulConstants.PleaseSelect;
          questionUserInput.IsFilled = false;
          break;
        }
        case Constants.ServiceCode.OtherAccountAndReconciliation: {
          const questionUserInput = service.Questions.find(
            (question) =>
              question.Code ===
              Constants.OtherAccountAndReconciliationQuestions.Q0407
          ).UserInput;
          questionUserInput.Answer = Constants.HelpfulConstants.PleaseSelect;
          questionUserInput.IsFilled = false;
          break;
        }
        case Constants.ServiceCode.PeriodReporting: {
          const questionUserInput = service.Questions.find(
            (question) =>
              question.Code === Constants.PeriodReportingQuestion.Q0513
          ).UserInput;
          questionUserInput.Answer = Constants.HelpfulConstants.PleaseSelect;
          questionUserInput.IsFilled = false;
          break;
        }
        case Constants.ServiceCode.AnnualReporting: {
          const questionUserInput = service.Questions.find(
            (question) =>
              question.Code === Constants.AnnualReportingQuestions.Q0608
          ).UserInput;
          questionUserInput.Answer = Constants.HelpfulConstants.PleaseSelect;
          questionUserInput.IsFilled = false;
          break;
        }
        case Constants.ServiceCode
          .PayrollAndExpenseAndTravelInvoiceManagement: {
          const questionUserInput = service.Questions.find(
            (question) =>
              question.Code ===
              Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
                .Q0763
          ).UserInput;
          questionUserInput.Answer = Constants.HelpfulConstants.PleaseSelect;
          questionUserInput.IsFilled = false;
          break;
        }
      }
    });
  }

  return serverReturn;
}
