import { useCallback, useState } from "react";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import { debounce, isValidDate } from "src/utils";
import * as Constants from "./../../../core/Constants";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function QuestionDateFieldInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const [fieldValue, setFieldValue] = useState<Date>(
    isValidDate(question.data.userValue as Date)
      ? (question.data.userValue as Date)
      : null
  );

  const debouncedChange = useCallback(
    debounce((date: Date | null) => {
      if (date !== null) {
        let updatedDate = null;
        if (isValidDate(date)) {
          updatedDate = date;
        }
        const newQuestionDTO: DTOs.QuestionDTO = {
          ...question,
          data: {
            ...question.data,
            userValue: updatedDate,
          },
        };

        onChange && onChange(newQuestionDTO);
      }
    }, Constants.INPUT_CHANGE_DEBOUNCE_MILLIS),
    [onChange, question]
  );

  function onInputValueChangeDelayed(date: Date | null): void {
    setFieldValue(date);
    debouncedChange(date);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateField
        data-testid={question.data.code}
        className="gt-questionRow__datePicker"
        format={question.data.inputConstraints.format as string}
        value={fieldValue}
        onChange={onInputValueChangeDelayed}
        disabled={isDisabled}
      />
    </LocalizationProvider>
  );
}

export default QuestionDateFieldInput;
