import { Autocomplete, Box, Button, FormGroup, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectableKeyValueItem } from "src/core/Models/Core.interface";

import * as Constants from "./../../../core/Constants";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {
  checkSelectableQuestionFilled,
  isFinalorOutdatedVersion,
  isSelectableQuestionDataFilled,
} from "src/utils";
import { AppContext } from "src/contexts/AppContext";
import { useContext } from "react";

function QuestionMultipleSelectInput({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const { globalState } = useContext(AppContext);
  const { t } = useTranslation();

  const userValueToArray =
    Array.isArray(question.data.userValue) && question.data.userValue.length > 0
      ? question.data.userValue
      : [{ key: "", value: Constants.HelpfulConstants.PleaseSelect }];
  const isFinalOrOutdatedVersion = isFinalorOutdatedVersion(globalState);

  const options: SelectableKeyValueItem[] = question.data.options.map(
    (option) => {
      const isSelected = userValueToArray.some((userValue) =>
        userValue ? userValue.toString() === option.key : null
      );
      return { ...option, isSelected };
    }
  );
  const onSelectedValueChange = (selectedOption: string, index: number) => {
    const updatedUserValueArray = [...userValueToArray] as string[];
    updatedUserValueArray[index] = selectedOption;

    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      data: {
        ...question.data,
        userValue: updatedUserValueArray as unknown as string,
      },
      state: {
        ...question.state,
        isFilled:
          question.state.isFilled &&
          isSelectableQuestionDataFilled(question) &&
          checkSelectableQuestionFilled(question),
      },
    };

    onChange && onChange(newQuestionDTO);
  };

  const addEntry = () => {
    if (userValueToArray.length < 10 && !isFinalOrOutdatedVersion) {
      const newValue = Constants.HelpfulConstants.PleaseSelect;

      const newArray = [...userValueToArray, newValue];
      const newQuestionDTO: DTOs.QuestionDTO = {
        ...question,
        data: {
          ...question.data,
          userValue: newArray as unknown as string,
        },
        state: {
          ...question.state,
          isFilled: false,
        },
      };
      onChange && onChange(newQuestionDTO);
    }
  };

  const deleteEntry = (index: number) => {
    const newUserValue = [
      ...userValueToArray.slice(0, index),
      ...userValueToArray.slice(index + 1),
    ];

    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      data: {
        ...question.data,
        userValue: newUserValue as unknown as string,
      },
    };

    onChange && onChange(newQuestionDTO);
  };

  return (
    <FormGroup>
      {userValueToArray.map((selectedValue, index) => (
        <div key={index} className="gt-multipleSelectContainer">
          <Autocomplete
            data-testid={question.data.code}
            className="gt-Autocomplete"
            disablePortal
            disabled={isDisabled}
            options={options}
            getOptionLabel={(option) => option.value}
            getOptionDisabled={(option) => option.isSelected === true}
            value={
              options.find(
                (option) => option.key == (selectedValue as unknown as string)
              ) || null
            }
            onChange={(syntheticBaseEvent, evt) => {
              const selectedOption =
                evt?.key || Constants.HelpfulConstants.PleaseSelect;
              onSelectedValueChange(selectedOption, index);
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                data-testid={`option_question${question.data.code}_option${option.key}`}
              >
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder={t("Options.PleaseSelect")} />
            )}
          />

          {userValueToArray.length > 1 && !isFinalOrOutdatedVersion && (
            <Button
              color="tertiary"
              className="gt-multipleEntriesInputContainerButton gt-multipleEntriesInputContainerButton--delete"
              onClick={() => deleteEntry(index)}
            >
              <PersonRemoveIcon />
            </Button>
          )}
        </div>
      ))}

      <Button
        className={`gt-multipleEntriesInputContainerButton 
                        ${
                          userValueToArray.length <
                            question.data.inputConstraints.maxEntrances &&
                          !isFinalOrOutdatedVersion
                            ? " gt-multipleEntriesInputContainerButton--add"
                            : " gt-multipleEntriesInputContainerButton--disabled"
                        }
                      }`}
        onClick={addEntry}
      >
        {t(`Forms.Button.${question.data.code}.Add`)}
      </Button>
    </FormGroup>
  );
}

export default QuestionMultipleSelectInput;
