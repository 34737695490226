import { useContext, useEffect, useState } from "react";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import "froala-editor/js/plugins/lists.min.js";
import FroalaEditor from "react-froala-wysiwyg";
import { isFinalorOutdatedVersion } from "src/utils";
import { AppContext } from "src/contexts/AppContext";

function QuestionRichTextEditor({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const { globalState } = useContext(AppContext);
  const [fieldValue, setFieldValue] = useState(question.data.userValue);
  const FROALA_KEY = process.env.REACT_APP_FROALA_LICENSE_KEY;

  function onInputValueChangeDelayed(evt: any): void {
    setFieldValue(evt);
    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      data: {
        ...question.data,
        userValue: evt,
      },
    };
    onChange && onChange(newQuestionDTO);
  }

  const isFinalVersion = isFinalorOutdatedVersion(globalState);
  useEffect(() => {
    setFieldValue(question.data.userValue);
  }, [question.data.userValue]);

  return (
    <FroalaEditor
      tag="textarea"
      data-testid={question.data.code}
      model={fieldValue}
      onModelChange={onInputValueChangeDelayed}
      config={{
        key: FROALA_KEY,
        placeholderText: "Start typing...",
        theme: "royal",
        charCounterCount: true,
        heightMin: 100,
        isDisabled: isDisabled,
        toolbarButtons: isFinalVersion
          ? []
          : [
              ["bold", "italic", "underline", "strikeThrough"],
              ["formatOL", "formatUL"],
              ["align", "outdent", "indent", "html", "undo", "redo"],
            ],
        events: {
          initialized: function () {
            const editorInstance = this as any;
            if (isFinalVersion) {
              editorInstance.edit.off();
            }
          },
        },
        readOnly: isFinalVersion,
      }}
    />
  );
}

export default QuestionRichTextEditor;
