import { CoreInterfaces } from "../Models";
import * as Constants from "../Constants";

export const TasksConfiguration: {
  [serviceCode in Constants.ServiceCode]: Array<CoreInterfaces.TaskConfiguration>;
} = {
  GeneralInformation: [],
  AccountsReceivable: [
    {
      Code: Constants.AccountReceivableTasks.T0101,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,

      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0102,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0103,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0104,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0105,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.SeniorConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0106,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0107,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0108,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0109,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0110,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.SeniorConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0111,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0112,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0113,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0114,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0115,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0116,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0117,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0118,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0119,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0120,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountReceivableTasks.T0121,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
  ],
  AccountsPayable: [
    {
      Code: Constants.AccountPayableTasks.T0201,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0202,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0203,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0204,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0205,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0206,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0207,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0225,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: Constants.AccountPayableQuestion.Q0227,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0208,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0226,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0209,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0210,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0211,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0227,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: Constants.AccountPayableQuestion.Q0227,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0212,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AccountPayableTasks.T0213,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
  ],
  CorporateCardManagement: [
    {
      Code: Constants.CorporateCardManagementTasks.T0301,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.CorporateCardManagementTasks.T0302,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.CorporateCardManagementTasks.T0303,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.CorporateCardManagementTasks.T0304,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.CorporateCardManagementTasks.T0305,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.CorporateCardManagementTasks.T0306,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.CorporateCardManagementTasks.T0307,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
  ],
  OtherAccountAndReconciliation: [
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0401,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0402,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0403,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0404,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0405,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0406,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.FrequencyXAmountXTime,
      CalculationQuestion:
        Constants.OtherAccountAndReconciliationQuestions.Q0404,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0407,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.FrequencyXAmountXTime,
      CalculationQuestion:
        Constants.OtherAccountAndReconciliationQuestions.Q0404,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0438,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Daily,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Engagement,
        Constants.TaskVisibilityArea.Calculation,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0439,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Engagement,
        Constants.TaskVisibilityArea.Calculation,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0440,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Engagement,
        Constants.TaskVisibilityArea.Calculation,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0441,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion:
        Constants.OtherAccountAndReconciliationQuestions.Q0414,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0442,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Engagement,
        Constants.TaskVisibilityArea.Calculation,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0408,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0409,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0410,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0411,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0412,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0413,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0414,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0415,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0416,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0417,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.FrequencyXAmountXTime,
      CalculationQuestion:
        Constants.OtherAccountAndReconciliationQuestions.Q0405,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0418,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0419,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0420,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0421,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0422,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.FrequencyXAmountXTime,
      CalculationQuestion:
        Constants.OtherAccountAndReconciliationQuestions.Q0405,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0423,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0424,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0425,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0426,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0427,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.OtherAccountAndReconciliationTasks.T0428,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
  ],
  PeriodReporting: [
    {
      Code: Constants.PeriodReportingTasks.T0501,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0502,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0503,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0504,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0505,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0506,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0507,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0508,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0509,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0510,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0511,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0512,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability: null,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0513,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0514,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability: null,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0515,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0516,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0517,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0518,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0519,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0520,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0521,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0522,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0523,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: null,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0532,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PeriodReportingTasks.T0533,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
  ],
  InterimListedCompanies: [
    {
      Code: Constants.InterimListedCompaniesTasks.T0559,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.InterimListedCompaniesTasks.T0560,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.InterimListedCompaniesTasks.T0561,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.InterimListedCompaniesTasks.T0562,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.InterimListedCompaniesTasks.T0563,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.InterimListedCompaniesTasks.T0564,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.InterimListedCompaniesTasks.T0565,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.InterimListedCompaniesTasks.T0566,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.InterimListedCompaniesTasks.T0567,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Quarterly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
  ],
  AnnualReporting: [
    {
      Code: Constants.AnnualReportingTasks.T0602,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0603,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0604,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: Constants.AnnualReportingQuestions.Q0602,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0605,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: Constants.AnnualReportingQuestions.Q0602,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0606,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0607,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0608,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability: null,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0609,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0610,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0611,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0612,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: Constants.AnnualReportingQuestions.Q0606,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0613,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: Constants.AnnualReportingQuestions.Q0606,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0614,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0615,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0616,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0617,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0618,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0619,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0620,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0621,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0623,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0624,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0625,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0626,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0627,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0628,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0629,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0630,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0631,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0632,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0633,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0634,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0635,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0636,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0637,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0638,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0639,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0640,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0641,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0642,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0643,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0644,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0645,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0646,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0647,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.StatutoryAnnualReport,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0649,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReportV2,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0650,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReportV2,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0651,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReportV2,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0652,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReportV2,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0653,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReportV2,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0655,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0656,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0657,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0658,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.CorporateIncomeTax,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingTasks.T0660,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AnnualReportV3,
      Type: Constants.TaskType.Automatic,
    },
  ],
  AnnualReportingListedCompanies: [
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6607,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6608,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6609,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6610,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6611,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6612,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6613,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6614,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6615,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6616,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualReportingListedCompaniesTasks.T6617,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
  ],
  PayrollAndExpenseAndTravelInvoiceManagement: [
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0701,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0702,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0703,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0704,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0705,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0706,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0707,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0708,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0709,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0710,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0711,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability: null,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollRun,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0712,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollReconciliation,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0713,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollReconciliation,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0714,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.AmountXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollReconciliation,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0715,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyPayrollReconciliation,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0716,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.PayrollServicesInAddition,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0717,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.PayrollServicesInAddition,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0718,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.PayrollServicesInAddition,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0719,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.PayrollServicesInAddition,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0720,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.PayrollServicesInAddition,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0721,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.PayrollServicesInAddition,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0722,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.PayrollServicesInAddition,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0723,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.PayrollServicesInAddition,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0724,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyReportingPayroll,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0725,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyReportingPayroll,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0726,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyReportingPayroll,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0727,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability: null,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyReportingPayroll,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0728,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyReportingPayroll,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0729,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyReportingPayroll,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0730,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyReportingPayroll,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0731,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.Client,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: null,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyReportingPayroll,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0732,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Monthly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.MonthlyReportingPayroll,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0733,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AdditionalPayrollServices,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0734,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AdditionalPayrollServices,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0735,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AdditionalPayrollServices,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0736,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AdditionalPayrollServices,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0737,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AdditionalPayrollServices,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0738,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AdditionalPayrollServices,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0739,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AdditionalPayrollServices,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0740,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AdditionalPayrollServices,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementTasks.T0741,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod:
        Constants.TaskCalculationMethod.FrequencyXTimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.AdditionalPayrollServices,
      Type: Constants.TaskType.Automatic,
    },
  ],
  AnnualPayrollRoutines: [
    {
      Code: Constants.AnnualPayrollRoutinesTasks.T0742,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualPayrollRoutinesTasks.T0743,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.WhenNeeded,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualPayrollRoutinesTasks.T0744,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualPayrollRoutinesTasks.T0745,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualPayrollRoutinesTasks.T0746,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualPayrollRoutinesTasks.T0747,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualPayrollRoutinesTasks.T0748,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualPayrollRoutinesTasks.T0749,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.AnnualPayrollRoutinesTasks.T0750,
      CanModifyAdjustmentFreq: true,
      CanModifyAllocationResponsibility: true,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [
        Constants.TaskVisibilityArea.Calculation,
        Constants.TaskVisibilityArea.Engagement,
      ],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantPayroll,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
  ],
  StartUpAccounting: [
    {
      Code: Constants.StartUpAccountingTasks.T0807,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0801,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0808,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0802,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.SeniorConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0809,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0803,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0810,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0804,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0811,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0801,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0812,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0802,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.SeniorConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0813,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0803,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0814,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0804,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0815,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0816,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.SeniorConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0817,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0818,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0819,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.SeniorConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0820,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.SeniorConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0821,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0801,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0822,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0802,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.SeniorConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0823,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0803,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.StartUpAccountingTasks.T0824,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: false,
      DefaultFreq: Constants.Frequency.OneTime,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: Constants.StartUpAccountingQuestions.Q0804,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
  ],
  StartUpPayroll: [],
  YearlyInternalDocumentationAndFormalities: [
    {
      Code: Constants.YearlyInternalDocumentationAndFormalitiesTasks.T1005,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.YearlyInternalDocumentationAndFormalitiesTasks.T1006,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.YearlyInternalDocumentationAndFormalitiesTasks.T1007,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.YearlyInternalDocumentationAndFormalitiesTasks.T1008,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.YearlyInternalDocumentationAndFormalitiesTasks.T1009,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.YearlyInternalDocumentationAndFormalitiesTasks.T1010,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ConsultantAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.YearlyInternalDocumentationAndFormalitiesTasks.T1011,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.YearlyInternalDocumentationAndFormalitiesTasks.T1012,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.AssistantManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
    {
      Code: Constants.YearlyInternalDocumentationAndFormalitiesTasks.T1013,
      CanModifyAdjustmentFreq: false,
      CanModifyAllocationResponsibility: false,
      CanModifyStaffLevel: true,
      DefaultFreq: Constants.Frequency.Yearly,
      DefaultAllocationOfResponsability:
        Constants.TaskAllocationResponsibility.GT,
      Visibility: [Constants.TaskVisibilityArea.Calculation],
      CalculationMethod: Constants.TaskCalculationMethod.TimeXPricePerHour,
      CalculationQuestion: null,
      EstimatedTimePerUnit: 0,
      StaffLevel: Constants.StaffLevel.ManagerAccounting,
      SumTimeInHrsAndMinutes: 0,
      IsActive: true,
      ServiceTaskGroup: Constants.TaskGroup.DefaultGroup,
      Type: Constants.TaskType.Automatic,
    },
  ],
  EngagementCounseling: [],
  LimitedCompany: [],
  NonProfitOrg: [],
  EconomicAssociation: [],
  AuditLawyerAndAccountingFirm: [],
  ServiceAgreement: [],
  CounselingTaxLetter: [],
  Education: [],
};
