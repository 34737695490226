import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "src/contexts/AppContext";
import { DTOs } from "src/core/Models";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  ceil,
  formatMinutesToHoursAndMinutes,
  getEngagementMonthlyStats,
} from "src/utils";
import * as Constants from "./../../../core/Constants";

function LeftMenu(): JSX.Element {
  const { globalState, businessUnit } = useContext(AppContext);
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  const toggleSidebarVisibility = () => {
    setIsVisible((current) => !current);
  };

  const companyName = globalState.generalVariables.companyName
    ? globalState.generalVariables.companyName
    : "";
  const organisationNumber = globalState.generalVariables.organisationNo
    ? globalState.generalVariables.organisationNo
    : "";
  const engagementTotalsPack = getEngagementMonthlyStats(globalState);
  const totalWorkingHours = formatMinutesToHoursAndMinutes(
    engagementTotalsPack.totalWorkTime
  );
  const totalCost = ceil(engagementTotalsPack.totalPricePerMonth);

  function goToSection(code: string): void {
    const panel = document.querySelector(
      `.gt-js-panel-virtual-element-for-scroll-${code}`
    );
    panel?.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  return (
    <aside
      className={`gt-leftSidebarContainer ${
        isVisible ? "" : "gt-leftSidebarContainer--hiddenState"
      }`}
    >
      <div
        className="gt-leftSidebarContainer-toggleVisibilityIcon"
        onClick={toggleSidebarVisibility}
      >
        <ChevronLeftIcon />
      </div>
      <section className="gt-leftSidebarContainer__generalInfo">
        <div className="gt-leftSidebarContainer__generalInfo-title">
          {companyName}
        </div>
        <div className="gt-leftSidebarContainer__generalInfo-row">
          <span className="gt-leftSidebarContainer__generalInfo-row-label">
            {t("General.OrgNo")}:
          </span>
          <span className="gt-leftSidebarContainer__generalInfo-row-value">
            {organisationNumber}
          </span>
        </div>

        {businessUnit !== Constants.BusinessUnit.Audit &&
          businessUnit !== Constants.BusinessUnit.Tax && (
            <>
              <div className="gt-leftSidebarContainer__generalInfo-row">
                <span className="gt-leftSidebarContainer__generalInfo-row-label">
                  {t("General.TotalWorkTime")}:
                </span>
                <span className="gt-leftSidebarContainer__generalInfo-row-value">
                  {totalWorkingHours}
                </span>
              </div>

              <div className="gt-leftSidebarContainer__generalInfo-row">
                <span className="gt-leftSidebarContainer__generalInfo-row-label">
                  {t("General.TotalPricePerMonth")}:
                </span>
                <span className="gt-leftSidebarContainer__generalInfo-row-value">
                  {totalCost} {Constants.USED_CURRENCY}
                </span>
              </div>
            </>
          )}
      </section>
      <div className="gt-leftSidebarContainer__wrapper">
        <div className="gt-leftSidebarContainer__wrapper-subtitle1">
          {businessUnit === Constants.BusinessUnit.Audit ||
          businessUnit === Constants.BusinessUnit.Tax
            ? t(`SideBar.${businessUnit}.SelectedServices`)
            : t("SideBar.SelectedServices")}
        </div>
        <div className="gt-leftSidebarContainer__wrapper-serviceLinks">
          {globalState?.services
            .filter(
              (eachService: DTOs.ServiceDTO) =>
                eachService.state.isSelected &&
                eachService.state.isVisibleInSideMenu
            )
            .map((selectedService: DTOs.ServiceDTO) => (
              <div
                key={`service_${selectedService.data.code}`}
                className="gt-leftSidebarContainer__wrapper-serviceLinks-selectedService"
                onClick={() => {
                  goToSection(selectedService.data.code);
                }}
              >
                {t(selectedService.data.title)}
              </div>
            ))}
        </div>
      </div>
    </aside>
  );
}

export default LeftMenu;
