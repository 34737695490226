import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import QuestionRow from "./Row/QuestionRow";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlinedBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import GtExpandMoreIcon from "../Common/GtExpandMoreIcon";
import { DTOs, CorePropsInterfaces } from "src/core/Models";
import { HelpfulConstants } from "src/core/Constants";
import { Button } from "@mui/material";
import { areAllFieldsCompleted } from "src/utils";
import { useContext } from "react";
import { AppContext } from "src/contexts/AppContext";
import * as Constants from "src/core/Constants/Constants";
import QuestionClientDetails from "./Inputs/QuestionClientDetails";

export default function ServiceQuestionsAccordion({
  service,
  areInputsDisabled,
  onChange,
  onExpandChange,
}: CorePropsInterfaces.ServiceQuestionsAccordionProps) {
  const { t } = useTranslation();
  const { businessUnit } = useContext(AppContext);

  const visibileQuestions = service.data.questions.filter(
    (item) =>
      item.state.isShown &&
      item.state.isVisibleInUI &&
      item.data.businessUnits.includes(
        businessUnit as unknown as Constants.BusinessUnit
      )
  );

  const questionsWithAnswerField = visibileQuestions.filter(
    (item) => !item.state.isInfoTextVariat && item.data.inputType
  );

  const isAllFilled = questionsWithAnswerField.every(
    (item) => item.state.isFilled && areAllFieldsCompleted(item)
  );

  const isAllQuestionAnsweredWithDefaultValue = questionsWithAnswerField
    .filter(
      (item) =>
        item.data.defaultValue &&
        item.data.defaultValue !== HelpfulConstants.PleaseSelect
    )
    .every((item) => item.data.defaultValue === item.data.userValue);

  function onAllFilledStateChange() {
    if (areInputsDisabled) {
      return;
    }
    const isAllAnswered = questionsWithAnswerField.every(
      (item) =>
        !!item.data.userValue &&
        item.data.userValue !== HelpfulConstants.PleaseSelect &&
        areAllFieldsCompleted(item)
    );

    questionsWithAnswerField.map((question) => {
      const newQuestionDTO: DTOs.QuestionDTO = {
        ...question,
        state: {
          ...question.state,
          isFilled: isAllAnswered ? !isAllFilled : question.state.isFilled,
        },
      };
      onChange(newQuestionDTO);
    });
  }

  return (
    <Accordion
      className={`gt-accordion 
      ${
        isAllFilled
          ? "gt-accordion--allQuestionsAnsweredState"
          : "gt-accordion--notAllQuestionsAnsweredState"
      }
      ${
        isAllQuestionAnsweredWithDefaultValue
          ? "gt-accordion--allQuestionsAnsweredIsDefaultState"
          : "gt-accordion--notAllQuestionsAnsweredIsDefaultState"
      }`}
      onChange={() => {
        onExpandChange();
      }}
      expanded={service.state.isExpandedQuestionAccordion}
    >
      <AccordionSummary
        expandIcon={<GtExpandMoreIcon isDarkColor={!isAllFilled} />}
        className="gt-accordion__header"
      >
        <div className="gt-accordion__header-container">
          <span className="gt-accordion__header-container-title">
            {t(service.data.title)}
          </span>
          <span className="gt-accordion__header-container-expandedText">
            <span className="gt-accordion__header-container-expandedText-icon">
              {service.data.questions.filter(
                (item) => item.state.isShown && item.data.defaultValue
              ).length > 0 && (
                <CircleRoundedIcon
                  className="gt-accordion__header-container-expandedText-icon"
                  fontSize="small"
                />
              )}
              {isAllFilled && (
                <CheckBoxIcon
                  className="gt-accordion__header-container-expandedText-icon-isAllFilledCheck"
                  fontSize="small"
                />
              )}
            </span>
            {service.state.isExpandedQuestionAccordion
              ? t("General.Collapse")
              : t("General.Expand")}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails className="gt-accordion__content">
        <div className="gt-questionPanel">
          <div className="gt-questionPanel__header">
            <div className="gt-questionPanel__header-item">
              {t("General.Question")}
            </div>
            <div className="gt-questionPanel__header-item">
              {t("General.Answer")}
            </div>
            <div className="gt-questionPanel__header-actions">
              {isAllFilled ? (
                <CheckBoxIcon
                  className="gt-questionPanel__header-actions-iconSelected"
                  fontSize="small"
                  onClick={() => onAllFilledStateChange()}
                />
              ) : (
                <CheckBoxOutlinedBlankIcon
                  className={`${
                    questionsWithAnswerField.every(
                      (item) =>
                        item.data.userValue &&
                        item.data.userValue !== HelpfulConstants.PleaseSelect &&
                        areAllFieldsCompleted(item)
                    )
                      ? "gt-questionPanel__header-actions-iconUnselected"
                      : "gt-questionPanel__header-actions-iconUnselectable"
                  }`}
                  fontSize="small"
                  onClick={() => onAllFilledStateChange()}
                />
              )}
            </div>
            <div className="gt-questionPanel__header-item">
              {t("General.Comment")}
            </div>
          </div>
          <div className="gt-questionPanel__rows">
            {visibileQuestions.map((question) =>
              question.data.inputType ===
              Constants.QuestionInputType.ClientDetails ? (
                question.data.inputType ===
                  Constants.QuestionInputType.ClientDetails && (
                  <QuestionClientDetails
                    key={`serviceQuestionRow${question.data.code}`}
                    isDisabled={areInputsDisabled}
                    question={question}
                    onChange={(question: DTOs.QuestionDTO) =>
                      onChange(question)
                    }
                  />
                )
              ) : (
                <QuestionRow
                  key={`serviceQuestionRow${question.data.code}`}
                  isDisabled={areInputsDisabled}
                  question={question}
                  onChange={(question: DTOs.QuestionDTO) => onChange(question)}
                />
              )
            )}
          </div>
          <div className="gt-questionPanel__footer">
            <div className="gt-questionPanel__footer-leftSidePlaceholder"></div>
            <div className="gt-questionPanel__footer-actions">
              {isAllFilled ? (
                <CheckBoxIcon
                  className="gt-questionPanel__footer-actions-iconSelected"
                  fontSize="small"
                  onClick={() => onAllFilledStateChange()}
                />
              ) : (
                <CheckBoxOutlinedBlankIcon
                  className={`${
                    questionsWithAnswerField.every(
                      (item) =>
                        item.data.userValue &&
                        item.data.userValue !== HelpfulConstants.PleaseSelect &&
                        areAllFieldsCompleted(item)
                    )
                      ? "gt-questionPanel__footer-actions-iconUnselected"
                      : "gt-questionPanel__footer-actions-iconUnselectable"
                  }`}
                  fontSize="small"
                  onClick={() => onAllFilledStateChange()}
                />
              )}
            </div>
            <div className="gt-questionPanel__footer-rightSidePlaceholder">
              <Button
                color="primary"
                variant="contained"
                onClick={onExpandChange}
                endIcon={<GtExpandMoreIcon rotate={true} isDarkColor={false} />}
              >
                {t("General.Hide")}
              </Button>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
