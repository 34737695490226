import { useContext, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Breadcrumbs,
  Button,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  CoreInterfaces,
  DTOs,
  ComponentStates,
  CorePropsInterfaces,
} from "src/core/Models";
import * as Constants from "src/core/Constants/Constants";
import i18n from "src/i18n";
import GtLogo from "src/components/Common/GtLogo";
import GtDialog from "src/components/Common/GtDialog";
import GtAuditLogs from "src/components/Common/GtAuditLogs";
import { AppContext } from "src/contexts/AppContext";
import { useQuery } from "src/core/Hooks";
import GtUserMenu from "src/components/Common/GtUserMenu";
import ChangeHourlyRatesDialog from "src/components/Header/ChangeHourlyRatesDialog";
import GtManageVersionsDialog from "src/components/Common/GtManageVersionsDialog";
import { handleConfigurationPersistence } from "src/utils/handle-configuration-utils";
import { isFinalorOutdatedVersion } from "src/utils/service-utils";
import GtImportConfigurationDialog from "src/components/Common/GtImportConfigurationDialog";
import GtDocumentSigningStatusIcon from "src/components/Common/GtDocumentSigningStatusIcon";
import GtUpdateRatesDialog from "src/components/Common/GtUpdateRatesDialog";
import GtSaveConflictDialog from "src/components/Common/GtSaveConflictDialog";
import { LoadingContext } from "src/contexts/LoadingContext";
import { useSnackBar } from "src/contexts/SnackbarContext";

function Header({
  areCtasButtonsHidden,
  areBreadcrumbsHidden,
  isBusinessUnit,
  isOfferStatusHidden,
  isVersionNameHidden,
  isOwnerNameHidden,
}: CorePropsInterfaces.HeaderProps): JSX.Element {
  const { globalState, dispatch, businessUnit } = useContext(AppContext);
  const { isLoading, updateApplicationLoadingState } =
    useContext(LoadingContext);
  const { t } = useTranslation();
  const { search } = useLocation();
  const queryParamsMap = useQuery();
  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();
  const [headerState, setHeaderState] = useState<ComponentStates.HeaderState>({
    isLoading: false,
    configurationDialog: {
      isVisible: false,
      saveAsNew: false,
      comment: "",
    },
    auditLogsDialog: {
      isVisible: false,
    },
    manageDialog: {
      isVisible: false,
      maxVersionNumber: 0,
    },
    hourlyRateDialog: {
      isVisible: false,
    },
    importConfigurationDialog: {
      isVisible: false,
    },
    updateRatesDialog: {
      isVisible: false,
    },
    hideFromImporting: {
      isVisible: false,
    },
  });

  const businessOpportunityId = queryParamsMap.get(
    Constants.QueryParams.businessOpportunityId
  );
  const clientId = queryParamsMap.get(Constants.QueryParams.clientId);
  const engagementConfigurationId = queryParamsMap.get(
    Constants.QueryParams.engagementConfigurationId
  );
  const breadcrumbs = [
    {
      title:
        businessUnit === Constants.BusinessUnit.Audit ||
        businessUnit === Constants.BusinessUnit.Tax
          ? t(`Pages.Services.${businessUnit}.Breadcrumb`)
          : t("Pages.Services.Title"),
      link: Constants.Routes.Home,
      businessUnit: [
        Constants.BusinessUnit.Audit,
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Tax,
      ],
    },
    {
      title: t("Pages.Questions.Title"),
      link: Constants.Routes.Question,
      businessUnit: [
        Constants.BusinessUnit.Audit,
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Tax,
      ],
    },
    {
      title: t("Pages.PriceAndTimeCalculation.Title"),
      link: Constants.Routes.PriceAndTimeCalculation,
      businessUnit: [Constants.BusinessUnit.Outsourcing],
    },
    {
      title: t("Pages.EngagementDescription.Title"),
      link: Constants.Routes.EngagementDescription,
      businessUnit: [Constants.BusinessUnit.Outsourcing],
    },
    {
      title: t("Pages.GenerateDocuments.Title"),
      link: Constants.Routes.GenerateDocuments,
      businessUnit: [
        Constants.BusinessUnit.Audit,
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Tax,
      ],
    },
  ];
  const configurationNo = globalState.currentConfiguration.version
    ? globalState.currentConfiguration.version
    : "1";
  const configurationName = t("General.ConfigurationVersionNumber", {
    configurationNo,
  });

  const maxConfigurationVersion = Math.max(
    0,
    headerState.manageDialog.maxVersionNumber
  );

  const nextConfigurationName = t("General.ConfigurationVersionNumber", {
    configurationNo: maxConfigurationVersion + 1,
  });

  const configurationStatus = globalState.currentConfiguration.status
    ? globalState.currentConfiguration.status
    : "";

  const description = globalState.currentConfiguration.description
    ? globalState.currentConfiguration.description
    : "";

  const businessOpportunityDescription = globalState.remoteData
    .businessOpportunityInfo?.Description
    ? globalState.remoteData.businessOpportunityInfo.Description
    : "";
  const ownerFullName = globalState.currentConfiguration.owner
    ? `${globalState.currentConfiguration.owner.firstName} ${globalState.currentConfiguration.owner.lastName}`
    : "";

  const isFinalorOutdatedVersionStatus = isFinalorOutdatedVersion(globalState);

  function changeLanguage(language: string) {
    i18n.changeLanguage(language);
    dispatch({
      type: Constants.AppStateActions.UpdateApplicationLanguage,
      payload: {
        language: language.toUpperCase(),
      },
    });
  }

  const hideFromImportingConfigurationDTO: DTOs.GtDialogDTO = {
    data: {
      title: globalState.currentConfiguration.isHiddenFromImporting
        ? t("General.UnhideConfiguration")
        : t("General.HideConfiguration"),
      maxWidth: "sm",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={() => toggleHiddenFromImportingDialog(false)}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          onClick={() => hideEngagement()}
        >
          {t("General.Save")}
        </Button>,
      ],
    },
    api: {
      onClose: () => toggleHiddenFromImportingDialog(false),
    },
    state: {
      isOpen: headerState.hideFromImporting.isVisible,
      isFullWidth: true,
    },
  };

  function handleConfigurationSave(saveAsNew = false): void {
    if (!isLoading) {
      if (!globalState.currentConfiguration.version || saveAsNew) {
        setHeaderState((currentHeaderState) => {
          return {
            ...currentHeaderState,
            configurationDialog: {
              ...currentHeaderState.configurationDialog,
              isVisible: true,
              saveAsNew,
            },
          };
        });
      } else {
        saveCurrentConfiguration();
      }
    }
  }

  function toggleImportConfigurationDialog(open: boolean): void {
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        importConfigurationDialog: {
          ...currentHeaderState.importConfigurationDialog,
          isVisible: open,
        },
      };
    });
  }

  function toggleHiddenFromImportingDialog(open: boolean): void {
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        hideFromImporting: {
          ...currentHeaderState.hideFromImporting,
          isVisible: open,
        },
      };
    });
  }

  async function hideEngagement(): Promise<void> {
    updateApplicationLoadingState(true);
    const status = globalState.currentConfiguration.isHiddenFromImporting;

    const globalStateUpdated = {
      ...globalState,
      currentConfiguration: {
        ...globalState.currentConfiguration,
        isHiddenFromImporting: !status,
      },
    };
    const apiUrl = Constants.APIPath.HideFromImporting;
    handleConfigurationPersistence(
      apiUrl,
      Constants.APIMethod.POST,
      globalStateUpdated,
      dispatch,
      businessOpportunityId,
      businessUnit,
      clientId,
      description,
      globalState.currentConfiguration.version,
      null,
      null,
      null,
      () => {
        dispatch({
          type: Constants.AppStateActions.UpdateHiddenFromImportingState,
          payload: {
            status:
              globalStateUpdated.currentConfiguration.isHiddenFromImporting,
          },
        });
      }
    ).then(() => {
      updateApplicationLoadingState(false);
      toggleHiddenFromImportingDialog(false);
    });
  }

  function toggleUpdateRatesDialog(open: boolean): void {
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        updateRatesDialog: {
          ...currentHeaderState.updateRatesDialog,
          isVisible: open,
        },
      };
    });
  }

  function saveCurrentConfiguration() {
    if (!headerState.isLoading) {
      if (!headerState.configurationDialog.isVisible) {
        updateApplicationLoadingState(true);
      }
      setHeaderState((currentHeaderState) => {
        return {
          ...currentHeaderState,
          isLoading: true,
        };
      });
      const fetchMethod =
        globalState.currentConfiguration.id &&
        !headerState.configurationDialog.saveAsNew
          ? Constants.APIMethod.PUT
          : Constants.APIMethod.POST;
      const url = headerState.configurationDialog.saveAsNew
        ? Constants.APIPath.EngagementConfigurationNewVersion
        : Constants.APIPath.EngagementConfiguration;
      const description =
        globalState.currentConfiguration.id &&
        !headerState.configurationDialog.saveAsNew
          ? globalState.currentConfiguration.description
          : headerState.configurationDialog.comment;
      let version =
        globalState.currentConfiguration.version === 0
          ? 1
          : globalState.currentConfiguration.version;
      if (headerState.configurationDialog.saveAsNew) {
        version = maxConfigurationVersion;
      }
      const digitalSigningDetails: CoreInterfaces.DigitalSigningDetails =
        headerState.configurationDialog.saveAsNew &&
        globalState.currentConfiguration.digitalSigningDetails
          ? {
              CaseId:
                globalState.currentConfiguration.digitalSigningDetails.CaseId,
              DocumentId: null,
              ExpirationDate: null,
            }
          : globalState.currentConfiguration.digitalSigningDetails;

      const engagementLetterProperties = headerState.configurationDialog
        .saveAsNew
        ? null
        : globalState.currentConfiguration.engagementLetterMetadata;

      handleConfigurationPersistence(
        url,
        fetchMethod,
        globalState,
        dispatch,
        businessOpportunityId,
        businessUnit,
        clientId,
        description,
        version,
        engagementLetterProperties,
        digitalSigningDetails
      )
        .then(
          (serverReturn: CoreInterfaces.EngagementConfigurationReadPack) => {
            if (headerState.configurationDialog.saveAsNew) {
              handleManageDialogClose();
            }
            setHeaderState((currentHeaderState) => {
              return {
                ...currentHeaderState,
                isLoading: false,
                configurationDialog: {
                  ...currentHeaderState.configurationDialog,
                  isVisible: false,
                  saveAsNew: false,
                  comment: "",
                },
              };
            });
            showSnackBar(t("General.ConfigurationSaveSuccess"), "success");
            if (engagementConfigurationId !== serverReturn.Id) {
              const urlSearchParams = new URLSearchParams(
                window.location.search
              );
              urlSearchParams.set(
                Constants.QueryParams.engagementConfigurationId,
                serverReturn.Id
              );
              navigate(`?${urlSearchParams}`, { replace: true });
            }
          }
        )
        .catch(() => {
          showSnackBar(t("General.ConfigurationSaveFail"), "error");
          setHeaderState((currentHeaderState) => {
            return {
              ...currentHeaderState,
              isLoading: false,
            };
          });
        })
        .finally(() => {
          setTimeout(() => {
            setHeaderState((currentHeaderState) => {
              return {
                ...currentHeaderState,
              };
            });
          }, 3000);
          if (!headerState.configurationDialog.isVisible) {
            updateApplicationLoadingState(false);
          }
        });
    }
  }

  function setConfigurationComment(configurationComment: string) {
    setHeaderState((currentAlertState) => {
      return {
        ...currentAlertState,
        configurationDialog: {
          ...currentAlertState.configurationDialog,
          comment: configurationComment,
        },
      };
    });
  }

  function handleConfigurationDialogClose() {
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        configurationDialog: {
          ...currentHeaderState.configurationDialog,
          isVisible: false,
          comment: "",
        },
      };
    });
  }

  const gtDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.Save"),
      maxWidth: "sm",
      rightButtons: [
        <Button
          disabled={headerState.isLoading}
          onClick={() => handleConfigurationDialogClose()}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          disabled={headerState.isLoading}
          onClick={() => saveCurrentConfiguration()}
          color="tertiary"
          variant="contained"
          key="confirmBtn"
        >
          {t("General.Save")}
        </Button>,
      ],
    },
    api: {
      onClose: handleConfigurationDialogClose,
    },
    state: {
      isOpen: headerState.configurationDialog.isVisible,
      isFullWidth: true,
    },
  };

  function handleAuditLogsDialogClose() {
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        auditLogsDialog: {
          ...currentHeaderState.auditLogsDialog,
          isVisible: false,
        },
      };
    });
  }

  function openAuditDialog(): void {
    updateApplicationLoadingState(true);
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        auditLogsDialog: {
          ...currentHeaderState.auditLogsDialog,
          isVisible: true,
        },
      };
    });
  }

  function openHourlyRateDialog(): void {
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        hourlyRateDialog: {
          ...currentHeaderState.hourlyRateDialog,
          isVisible: true,
        },
      };
    });
  }

  function navigateToEngagementLettersSummaryView(): void {
    navigate(`${Constants.Routes.EngagementLettersSummaryView}`);
  }

  function onHourlyRateDialogClose(
    alertConfiguration: CoreInterfaces.AlertConfiguration
  ): void {
    if (!!alertConfiguration && alertConfiguration.isDisplayed) {
      showSnackBar(t(alertConfiguration.message), alertConfiguration.severity);
    }
    setHeaderState((currentHeaderState) => {
      const nextHeaderState = {
        ...currentHeaderState,
        hourlyRateDialog: {
          ...currentHeaderState.hourlyRateDialog,
          isVisible: false,
        },
      };
      return nextHeaderState;
    });
    if (!!alertConfiguration) {
      setTimeout(() => {
        setHeaderState((currentHeaderState) => {
          return {
            ...currentHeaderState,
          };
        });
      }, 3000);
    }
  }

  const auditDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.History"),
      maxWidth: "md",
      rightButtons: [],
    },
    api: {
      onClose: handleAuditLogsDialogClose,
    },
    state: {
      isOpen: headerState.auditLogsDialog.isVisible,
      isFullWidth: true,
    },
  };

  function handleManageDialogClose() {
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        manageDialog: {
          ...currentHeaderState.manageDialog,
          isVisible: false,
          engagementVersions: [],
        },
      };
    });
  }

  function openManageDialog(): void {
    updateApplicationLoadingState(true);
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        manageDialog: {
          ...currentHeaderState.manageDialog,
          isVisible: true,
        },
      };
    });
  }

  function setMaxVersionNumber(value: number): void {
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        manageDialog: {
          ...currentHeaderState.manageDialog,
          maxVersionNumber: value,
        },
      };
    });
  }

  function manageDialogClose(
    alertConfiguration?: CoreInterfaces.AlertConfiguration
  ): void {
    if (alertConfiguration && alertConfiguration.isDisplayed) {
      showSnackBar(t(alertConfiguration.message), alertConfiguration.severity);
    }
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        isLoading: false,
        manageDialog: {
          ...currentHeaderState.manageDialog,
          isVisible: false,
        },
      };
    });
  }

  function afterConfigurationImport(
    alertConfiguration: CoreInterfaces.AlertConfiguration
  ): void {
    if (alertConfiguration && alertConfiguration.isDisplayed) {
      showSnackBar(t(alertConfiguration.message), alertConfiguration.severity);
    }
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        isLoading: false,
        manageDialog: {
          ...currentHeaderState.manageDialog,
          isVisible: false,
        },
        importConfigurationDialog: {
          ...currentHeaderState.importConfigurationDialog,
          isVisible: false,
        },
      };
    });
  }

  function afterUpdateRates(
    alertConfiguration: CoreInterfaces.AlertConfiguration
  ): void {
    if (alertConfiguration && alertConfiguration.isDisplayed) {
      showSnackBar(t(alertConfiguration.message), alertConfiguration.severity);
    }
    setHeaderState((currentHeaderState) => {
      return {
        ...currentHeaderState,
        isLoading: false,
        manageDialog: {
          ...currentHeaderState.manageDialog,
          isVisible: false,
        },
        updateRatesDialog: {
          ...currentHeaderState.updateRatesDialog,
          isVisible: false,
        },
      };
    });
  }

  return (
    <>
      <GtDialog gtDialogDTO={auditDialogDTO}>
        <GtAuditLogs />
      </GtDialog>

      {headerState.manageDialog.isVisible && (
        <GtManageVersionsDialog
          onManageDialogClose={(
            configurationSaveState?: CoreInterfaces.AlertConfiguration
          ) => manageDialogClose(configurationSaveState)}
          onSaveNewConfiguration={(maxVersionNumber: number) => {
            setMaxVersionNumber(maxVersionNumber);
            handleConfigurationSave(true);
          }}
          onImportConfigurationOpen={() =>
            toggleImportConfigurationDialog(true)
          }
          onUpdateRatesOpen={() => {
            toggleUpdateRatesDialog(true);
          }}
          onHideFromImporting={() => toggleHiddenFromImportingDialog(true)}
        />
      )}

      {headerState.importConfigurationDialog.isVisible && (
        <GtImportConfigurationDialog
          onDialogClose={() => toggleImportConfigurationDialog(false)}
          afterConfigurationImport={(
            alertConfiguration: CoreInterfaces.AlertConfiguration
          ) => afterConfigurationImport(alertConfiguration)}
        />
      )}

      {headerState.hideFromImporting.isVisible && (
        <GtDialog gtDialogDTO={hideFromImportingConfigurationDTO}>
          <article>
            {t("General.HideQuestion", {
              status: globalState.currentConfiguration.isHiddenFromImporting
                ? t("General.UnhideStatus")
                : t("General.HideStatus"),
            })}
          </article>
        </GtDialog>
      )}

      {globalState.showConflictUpdateDialog && (
        <GtSaveConflictDialog lastModifiedBy={globalState.lastModifiedBy} />
      )}

      {headerState.updateRatesDialog.isVisible && (
        <GtUpdateRatesDialog
          onDialogClose={() => toggleUpdateRatesDialog(false)}
          afterUpdateRates={(
            alertConfiguration: CoreInterfaces.AlertConfiguration
          ) => afterUpdateRates(alertConfiguration)}
        />
      )}

      <GtDialog gtDialogDTO={gtDialogDTO}>
        <article className="gt-saveFormContent">
          <section className="gt-saveFormContent__row">
            <div className="gt-saveFormContent__row-titleContainer">
              <span className="gt-saveFormContent__row-titleContainer-title">
                {t("General.ConfigurationName")}
              </span>
            </div>
            <div className="gt-saveFormContent__row-inputContainer">
              <TextField
                disabled
                className="gt-TextField"
                variant="outlined"
                value={nextConfigurationName}
              />
            </div>
          </section>
          <section className="gt-saveFormContent__row">
            <div className="gt-saveFormContent__row-titleContainer">
              <span className="gt-saveFormContent__row-titleContainer-title">
                {t("General.Comments")}
              </span>
            </div>
            <div className="gt-saveFormContent__row-inputContainer">
              <TextField
                className="gt-MultilineField"
                multiline
                minRows={5}
                inputProps={{ maxLength: 300 }}
                variant="outlined"
                onChange={(evt) => setConfigurationComment(evt.target.value)}
                value={headerState.configurationDialog.comment}
              />
            </div>
          </section>
        </article>
      </GtDialog>
      <article className="gt-mainHeader">
        <section className="gt-mainHeader__topUtilities">
          <GtLogo />

          <div className="gt-mainHeader__topUtilities-actionSide">
            <Select
              className="gt-mainHeader__topUtilities-actionSide-languageSelector"
              value={i18n.language}
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <MenuItem value={"en"}>EN</MenuItem>
              <MenuItem value={"se"}>SE</MenuItem>
            </Select>
            <GtUserMenu />
          </div>
        </section>
        <section
          className="gt-mainHeader__primaryRow"
          style={{ backgroundImage: "url(/resources/gt-header.png)" }}
        >
          <div className="gt-mainHeader__primaryRow-info">
            <Typography
              className="gt-mainHeader__primaryRow-info-title"
              variant="h5"
            >
              {t("General.AppTitle")}
            </Typography>
            <div className="gt-mainHeader__primaryRow-info-data">
              {!isBusinessUnit && (
                <>
                  <span className="gt-mainHeader__primaryRow-info-data-label">
                    {t("General.BusinessArea")}:
                  </span>
                  <span className="gt-mainHeader__primaryRow-info-data-value">
                    {t(`Options.BusinessArea.${businessUnit}`)}
                  </span>
                </>
              )}
              {!isVersionNameHidden && (
                <>
                  <span className="gt-mainHeader__primaryRow-info-data-label">
                    {t("General.ConfigurationName")}:
                  </span>
                  <Tooltip
                    title={
                      (description || businessOpportunityDescription) && (
                        <article className="gt-descriptionTooltip">
                          {description && (
                            <>
                              <section className="gt-descriptionTooltip__title">
                                {t("General.ConfigurationDescription")}
                              </section>
                              <section className="gt-descriptionTooltip__description">
                                {description}
                              </section>
                            </>
                          )}
                          {businessOpportunityDescription && (
                            <>
                              <section className="gt-descriptionTooltip__title">
                                {t("General.BusinessOpportunityDescription")}
                              </section>
                              <section className="gt-descriptionTooltip__description">
                                {businessOpportunityDescription}
                              </section>
                            </>
                          )}
                        </article>
                      )
                    }
                  >
                    <span className="gt-mainHeader__primaryRow-info-data-value">
                      {configurationName}
                    </span>
                  </Tooltip>
                </>
              )}

              {!isOfferStatusHidden && (
                <>
                  <span className="gt-mainHeader__primaryRow-info-data-label">
                    {t("General.OfferStatus")}:
                  </span>
                  {configurationStatus && (
                    <span className="gt-mainHeader__primaryRow-info-data-value">
                      {globalState.currentConfiguration.signingStatus ===
                      Constants.SigningStatus.SentForDigitallySigned
                        ? t(`General.SentForDigitallySignedText`)
                        : t(
                            `Options.ConfigurationStatus.${configurationStatus}`
                          )}
                      {globalState.currentConfiguration.signingStatus && (
                        <GtDocumentSigningStatusIcon
                          status={
                            globalState.currentConfiguration
                              .signingStatus as Constants.SigningStatus
                          }
                          signingDate={
                            globalState.currentConfiguration.signingDate
                          }
                        />
                      )}
                    </span>
                  )}
                </>
              )}
              {!isOwnerNameHidden && (
                <>
                  <span className="gt-mainHeader__primaryRow-info-data-label">
                    {t("General.Owner")}:
                  </span>
                  <span className="gt-mainHeader__primaryRow-info-data-value">
                    {ownerFullName}
                  </span>
                </>
              )}
            </div>
          </div>

          {!areCtasButtonsHidden && (
            <div className="gt-mainHeader__primaryRow-ctas">
              {!!globalState.currentConfiguration.id && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openAuditDialog}
                >
                  {t("General.History")}
                </Button>
              )}
              {!!globalState.currentConfiguration.id &&
                !isFinalorOutdatedVersionStatus &&
                businessUnit !== Constants.BusinessUnit.Audit &&
                businessUnit !== Constants.BusinessUnit.Tax && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={openHourlyRateDialog}
                  >
                    {t("General.ChangeHourlyRate")}
                  </Button>
                )}
              <Button
                color="primary"
                variant="contained"
                onClick={navigateToEngagementLettersSummaryView}
              >
                {t("General.LettersSummaryView")}
              </Button>
              {headerState.hourlyRateDialog.isVisible && (
                <ChangeHourlyRatesDialog onClose={onHourlyRateDialogClose} />
              )}
              {!!globalState.currentConfiguration.id && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openManageDialog}
                >
                  {t("General.Manage")}
                </Button>
              )}
              {!isFinalorOutdatedVersionStatus && (
                <Button
                  onClick={() => handleConfigurationSave()}
                  color="secondary"
                  variant="contained"
                >
                  {t("General.Save")}
                </Button>
              )}
            </div>
          )}
        </section>
        {!areBreadcrumbsHidden && (
          <section className="gt-mainHeader__breadcrumbsContainer">
            <Breadcrumbs
              className="gt-mainHeader__breadcrumbsContainer-breadcrumbs"
              separator={
                <NavigateNextIcon
                  className="gt-mainHeader__breadcrumbsContainer-nextIcon"
                  fontSize="small"
                />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs
                .filter((breadcrumbPack) =>
                  breadcrumbPack.businessUnit.includes(
                    businessUnit as Constants.BusinessUnit
                  )
                )
                .map((breadcrumbPack) => (
                  <NavLink
                    className={({ isActive }) => {
                      const cssClasses =
                        "gt-mainHeader__breadcrumbsContainer-breadcrumbs-item";
                      return isActive
                        ? `${cssClasses} gt-mainHeader__breadcrumbsContainer-breadcrumbs-item--active`
                        : cssClasses;
                    }}
                    key={breadcrumbPack.link}
                    to={`/${breadcrumbPack.link}${search}`}
                    caseSensitive
                  >
                    {breadcrumbPack.title}
                  </NavLink>
                ))}
            </Breadcrumbs>
          </section>
        )}
      </article>
    </>
  );
}

export default Header;
