import { useContext, useState } from "react";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { CorePropsInterfaces, CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "../../../core/Constants";
import { AppContext } from "src/contexts/AppContext";
import {
  areAllFieldsCompleted,
  createEmptyClient,
  findQuestionInService,
  isClientDetailsDataFilled,
  isFinalorOutdatedVersion,
} from "src/utils";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import GtDebouncedInput from "src/components/Common/GtDebouncedInput";
import GtDialog from "src/components/Common/GtDialog";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlinedBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";

function QuestionClientDetails({
  question,
  isDisabled,
  onChange,
}: CorePropsInterfaces.QuestionInputProps) {
  const { globalState, dispatch } = useContext(AppContext);
  const lastVersionChangedDate =
    globalState.currentConfiguration.lastModifiedDate;
  const isFinalOrOutdatedVersion = isFinalorOutdatedVersion(globalState);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contactPersonToDeleteUuid, setContactPersonToDeleteUuid] =
    useState<string>();
  const { t } = useTranslation();

  const userDetails =
    Array.isArray(question.data.userValue) && question.data.userValue.length > 0
      ? (question.data.userValue as CoreInterfaces.ClientDetails[])
      : [createEmptyClient(globalState, true)];

  const isDigitalSigning =
    findQuestionInService(
      globalState.services.find(
        (service) =>
          service.data.code === Constants.ServiceCode.GeneralInformation
      ),
      Constants.GeneralInformationQuestion.Q0017
    )?.data.userValue === Constants.YesNo.Yes;

  const isAllDataFilled = areAllFieldsCompleted(question);
  const addSignee = (clientIndex: number) => {
    if (!isFinalOrOutdatedVersion) {
      const updatedUserDetails = [...userDetails];
      if (!Array.isArray(updatedUserDetails[clientIndex].Signees)) {
        updatedUserDetails[clientIndex].Signees = [];
      }

      const updatedSignees = [
        ...updatedUserDetails[clientIndex].Signees,
        { Name: "", Email: "" },
      ];

      updatedUserDetails[clientIndex].Signees = updatedSignees;

      dispatch({
        type: Constants.AppStateActions.UpdateClientDetailsValue,
        payload: {
          contactPersonUuid: updatedUserDetails[clientIndex].Uuid,
          property: "Signees",
          value: updatedUserDetails[clientIndex].Signees,
        },
      });
    }
  };

  const updateSignee = (
    clientIndex: number,
    signeeIndex: number,
    field: string,
    value: string
  ) => {
    if (!isFinalOrOutdatedVersion) {
      const updatedUserDetails = [...userDetails];

      if (!Array.isArray(updatedUserDetails[clientIndex].Signees)) {
        updatedUserDetails[clientIndex].Signees = [];
      } else {
        updatedUserDetails[clientIndex].Signees = [
          ...updatedUserDetails[clientIndex].Signees,
        ];
      }

      updatedUserDetails[clientIndex].Signees[signeeIndex] = {
        ...updatedUserDetails[clientIndex].Signees[signeeIndex],
        [field]: value,
      };

      dispatch({
        type: Constants.AppStateActions.UpdateClientDetailsValue,
        payload: {
          contactPersonUuid: updatedUserDetails[clientIndex].Uuid,
          property: "Signees",
          value: updatedUserDetails[clientIndex].Signees,
        },
      });
    }
  };

  const deleteSignee = (clientIndex: number, signeeIndex: number) => {
    if (!isFinalOrOutdatedVersion) {
      const updatedUserDetails = [...userDetails];
      const updatedSignees = updatedUserDetails[clientIndex].Signees.filter(
        (_, index) => index !== signeeIndex
      );

      updatedUserDetails[clientIndex].Signees = updatedSignees;

      dispatch({
        type: Constants.AppStateActions.UpdateClientDetailsValue,
        payload: {
          contactPersonUuid: updatedUserDetails[clientIndex].Uuid,
          property: "Signees",
          value: updatedUserDetails[clientIndex].Signees,
        },
      });
    }
  };

  const deleteContactPerson = () => {
    dispatch({
      type: Constants.AppStateActions.DeleteClientDetails,
      payload: {
        contactPersonToDeleteUuid,
      },
    });
  };

  const deleteDialogDTO: DTOs.GtDialogDTO = {
    data: {
      title: t("General.ClientDetails.DeleteClient"),
      maxWidth: "xs",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={() => setIsModalVisible(!isModalVisible)}
          color="inherit"
          variant="contained"
          key="cancelAction"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          onClick={() => {
            deleteContactPerson();
            setIsModalVisible(!isModalVisible);
          }}
          color="tertiary"
          variant="contained"
          key="delecteLicense"
        >
          {t("General.ClientDetails.DeleteClient")}
        </Button>,
      ],
    },
    api: {
      onClose: () => setIsModalVisible(!isModalVisible),
    },
    state: {
      isOpen: isModalVisible,
      isFullWidth: true,
    },
  };

  const updateContactPersonProperty = ({
    value,
    contactPersonUuid,
    property,
    signeeIndex,
  }: {
    value: string;
    contactPersonUuid: string;
    property: keyof CoreInterfaces.ClientDetails;
    signeeIndex?: number;
  }): void => {
    dispatch({
      type: Constants.AppStateActions.UpdateClientDetailsValue,
      payload: {
        contactPersonUuid,
        property,
        value,
        signeeIndex,
      },
    });
  };
  const isClientDetailsQuestion =
    question.data.inputType === Constants.QuestionInputType.ClientDetails;
  let isClientDetailsQuestionFilled = false;
  if (isClientDetailsQuestion) {
    isClientDetailsQuestionFilled = isClientDetailsDataFilled(question);
  }

  const isDataIncomplete =
    isClientDetailsQuestion && !isClientDetailsQuestionFilled;

  function addNewClient(): void {
    if (userDetails.length < 10 && !isFinalOrOutdatedVersion) {
      dispatch({
        type: Constants.AppStateActions.AddClientDetails,
      });
    }
  }

  function onFilledStateChange(isFilled: boolean) {
    if (isDisabled) {
      return;
    }
    const newQuestionDTO: DTOs.QuestionDTO = {
      ...question,
      state: {
        ...question.state,
        isFilled:
          question.data.userValue && isDataIncomplete ? false : isFilled,
      },
    };
    onChange && onChange(newQuestionDTO);
  }

  return (
    <article
      className={`gt-questionRow gt-questionRow--clientDetails
         ${
           !question.data.userValue ||
           question.data.userValue ===
             Constants.HelpfulConstants.PleaseSelect ||
           isDataIncomplete
             ? "gt-questionRow--isNotAnsweredState"
             : ""
         } 
            ${
              question.state.isFilled && isAllDataFilled
                ? "gt-questionRow--filledAnswerState"
                : ""
            }
            ${
              !question.state.isFilled && isAllDataFilled
                ? "gt-questionRow--unfilledAnswerState"
                : ""
            }`}
    >
      {isModalVisible && (
        <GtDialog gtDialogDTO={deleteDialogDTO}>
          <article> {t("General.ClientDetails.DeleteClient")}</article>
        </GtDialog>
      )}
      {userDetails.map((contactPerson, index) => (
        <section key={contactPerson.Uuid} className="gt-clientDetails">
          <div className="gt-clientDetails__header">
            <p>
              {t("General.ClientDetails.Client")} {index + 1}
            </p>
            {index > 0 && (
              <Button
                className="gt-clientDetails__row-buttons-delete"
                onClick={() => {
                  setContactPersonToDeleteUuid(contactPerson.Uuid);
                  setIsModalVisible(true);
                }}
              >
                <PersonRemoveIcon />
              </Button>
            )}
          </div>

          <div className="gt-clientDetails__row">
            <label>{t("General.ClientDetails.ClientName")} </label>
            <TextField
              value={contactPerson.ClientName ?? ""}
              className="gt-TextField"
              onChange={(event: any) =>
                updateContactPersonProperty({
                  value: event.target.value,
                  contactPersonUuid: contactPerson.Uuid,
                  property: "ClientName",
                })
              }
              placeholder={t("Options.ClientName")}
              disabled={isFinalOrOutdatedVersion || index === 0}
            />
            <section className="gt-questionRow__indicators"></section>
            <section className="gt-questionRow__comment"></section>
          </div>

          <div className="gt-clientDetails__row">
            <label>
              {contactPerson.ClientType !== Constants.ClientType.PrivatePerson
                ? t("General.ClientDetails.ClientOrgNumber")
                : t("General.ClientDetails.PrivateClientOrgNumber")}
            </label>
            <GtDebouncedInput
              value={contactPerson.ClientOrgNr ?? ""}
              className="gt-TextField"
              onChange={(value: any) =>
                updateContactPersonProperty({
                  value,
                  contactPersonUuid: contactPerson.Uuid,
                  property: "ClientOrgNr",
                })
              }
              placeholder={
                contactPerson.ClientType !== Constants.ClientType.PrivatePerson
                  ? t("General.ClientDetails.ClientOrgNumber")
                  : t("General.ClientDetails.PrivateClientOrgNumber")
              }
              updateTimestamp={lastVersionChangedDate}
              disabled={isFinalOrOutdatedVersion || index === 0}
            />
            <section className="gt-questionRow__indicators"></section>
            <section className="gt-questionRow__comment"></section>
          </div>
          {index === 0 && (
            <>
              <div className="gt-clientDetails__row">
                <label>{t("General.ClientDetails.StreetAddress")} </label>
                <GtDebouncedInput
                  value={contactPerson.StreetAdress ?? ""}
                  className="gt-TextField"
                  onChange={(value: any) =>
                    updateContactPersonProperty({
                      value,
                      contactPersonUuid: contactPerson.Uuid,
                      property: "StreetAdress",
                    })
                  }
                  placeholder={t("General.ClientDetails.StreetAddress")}
                  updateTimestamp={lastVersionChangedDate}
                  disabled={isFinalOrOutdatedVersion || index === 0}
                />
                <section className="gt-questionRow__indicators"></section>
                <section className="gt-questionRow__comment"></section>
              </div>
              <div className="gt-clientDetails__row">
                <label>{t("General.ClientDetails.Address")} </label>
                <GtDebouncedInput
                  value={contactPerson.Address ?? ""}
                  className="gt-TextField"
                  onChange={(value: any) =>
                    updateContactPersonProperty({
                      value,
                      contactPersonUuid: contactPerson.Uuid,
                      property: "Address",
                    })
                  }
                  placeholder={t("General.ClientDetails.Address")}
                  updateTimestamp={lastVersionChangedDate}
                  disabled={isFinalOrOutdatedVersion || index === 0}
                />
                <section className="gt-questionRow__indicators"></section>
                <section className="gt-questionRow__comment"></section>
              </div>
            </>
          )}
          <div className="gt-clientDetails__row">
            <div className="gt-clientDetails__row-title">
              <label>{t("General.ClientDetails.ClientType")}</label>
              <Tooltip
                className="gt-questionRow__title-tooltip"
                data-testid={question.data.code + "Tooltip"}
                title={
                  <span
                    className="gt-tootipHint"
                    data-testid={question.data.code + "TooltipHint"}
                    dangerouslySetInnerHTML={{
                      __html: t("General.ClientDetails.ClientTypeTooltip"),
                    }}
                  ></span>
                }
              >
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Select
              data-testid={question.data.code}
              className="gt-Select"
              value={(contactPerson.ClientType as string) ?? ""}
              onChange={(event) =>
                updateContactPersonProperty({
                  value: event.target.value as Constants.ClientType,
                  contactPersonUuid: contactPerson.Uuid,
                  property: "ClientType",
                })
              }
              disabled={
                question.data.inputConstraints.nonEditableAnswer || isDisabled
              }
            >
              {question.data.options &&
                question.data.options.map((eachOption) => (
                  <MenuItem
                    data-testid={`option_question${question.data.code}_option${eachOption.key}`}
                    key={`option_question${question.data.code}_option${eachOption.key}`}
                    value={eachOption.key}
                  >
                    {eachOption.untranslatable
                      ? eachOption.value
                      : t(eachOption.value)}
                  </MenuItem>
                ))}
            </Select>
            <section className="gt-questionRow__indicators"></section>
            <section className="gt-questionRow__comment"></section>
          </div>

          {contactPerson.ClientType === Constants.ClientType.Company && (
            <div className="gt-clientDetails__signees">
              <label>{t("General.ClientDetails.AddSignees")}</label>
              {Array.isArray(contactPerson.Signees) &&
                contactPerson.Signees.map((signee, signeeIndex) => (
                  <div key={signeeIndex} className="gt-clientDetails__signee">
                    <TextField
                      key={signeeIndex + contactPerson.Uuid}
                      value={signee.Name ?? ""}
                      className="gt-TextField"
                      onChange={(event: any) =>
                        updateSignee(
                          index,
                          signeeIndex,
                          "Name",
                          event.target.value
                        )
                      }
                      placeholder={t("General.ClientDetails.ClientSignee")}
                      disabled={isFinalOrOutdatedVersion}
                    />
                    {isDigitalSigning ? (
                      <TextField
                        key={signeeIndex + contactPerson.Uuid + "Email"}
                        error={
                          !!signee.Email &&
                          !Constants.EmailRegex.test(signee.Email)
                        }
                        id="outlined-error"
                        value={signee.Email ?? ""}
                        className="gt-TextField"
                        onChange={(event: any) =>
                          updateSignee(
                            index,
                            signeeIndex,
                            "Email",
                            event.target.value
                          )
                        }
                        placeholder={t("General.ClientDetails.EmailSignee")}
                        disabled={isFinalOrOutdatedVersion}
                      />
                    ) : (
                      <section className="gt-questionRow__comment"></section>
                    )}
                    <Button
                      className="gt-clientDetails__delete-signee"
                      onClick={() => deleteSignee(index, signeeIndex)}
                      disabled={isFinalOrOutdatedVersion}
                    >
                      <PersonRemoveIcon />
                    </Button>
                    <section className="gt-questionRow__indicators"></section>
                    <section className="gt-questionRow__comment"></section>
                  </div>
                ))}
              <Button
                className={`gt-clientDetails__add-signee 
                        ${
                          isFinalOrOutdatedVersion
                            ? "gt-clientDetails__add-signee--disabled"
                            : ""
                        }`}
                onClick={() => addSignee(index)}
                disabled={isFinalOrOutdatedVersion}
              >
                {t("General.ClientDetails.AddSigneeTextButton")}
              </Button>
            </div>
          )}

          {contactPerson.ClientType === Constants.ClientType.PrivatePerson &&
            isDigitalSigning && (
              <div className="gt-clientDetails__row">
                <label>{t("General.ClientDetails.EmailSignee")}</label>
                <TextField
                  error={
                    !!contactPerson.GTSignee &&
                    !Constants.EmailRegex.test(contactPerson.GTSignee)
                  }
                  id="outlined-error"
                  value={contactPerson.GTSignee ?? ""}
                  className="gt-TextField"
                  onChange={(event: any) =>
                    updateContactPersonProperty({
                      value: event.target.value,
                      contactPersonUuid: contactPerson.Uuid,
                      property: "GTSignee",
                    })
                  }
                  placeholder={t("General.ClientDetails.EmailSignee")}
                  disabled={isFinalOrOutdatedVersion}
                />
                <section className="gt-questionRow__indicators"></section>
                <section className="gt-questionRow__comment"></section>
              </div>
            )}

          <div className="gt-clientDetails__row-buttons">
            <section className="gt-questionRow__comment"></section>

            {index === userDetails.length - 1 && (
              <Button
                className={`gt-clientDetails__row-buttons-addClient ${
                  userDetails.length <= 9 && !isFinalOrOutdatedVersion
                    ? "enabled"
                    : "disabled"
                }`}
                onClick={addNewClient}
              >
                {t("General.ClientDetails.AddClientDetails")}
              </Button>
            )}

            {index === userDetails.length - 1 && (
              <>
                <section className="gt-questionRow__indicators">
                  <span className="gt-questionRow__indicators-icon">
                    <CircleRoundedIcon fontSize="small" />
                  </span>
                  <span className="gt-questionRow__indicators-iconFill">
                    {question.state.isFilled && isAllDataFilled ? (
                      <CheckBoxIcon
                        fontSize="small"
                        onClick={() =>
                          onFilledStateChange(!question.state.isFilled)
                        }
                      />
                    ) : (
                      <CheckBoxOutlinedBlankIcon
                        fontSize="small"
                        onClick={() =>
                          onFilledStateChange(!question.state.isFilled)
                        }
                      />
                    )}
                  </span>
                </section>
                <section className="gt-questionRow__comment">
                  <TextField
                    className="gt-questionRow__comment-input"
                    multiline
                    maxRows={2}
                    inputProps={{ maxLength: 300 }}
                    disabled={isDisabled}
                  />
                </section>
              </>
            )}
          </div>
        </section>
      ))}
    </article>
  );
}

export default QuestionClientDetails;
