import { CoreInterfaces, DTOs } from "src/core/Models";
import * as Constants from "./../core/Constants";
import { t } from "i18next";
import { isValidDate, calculateYearsDifference } from "./utils";
import { v4 as uuid } from "uuid";
import { ChangeEvent } from "react";
import { AppState, ClientSignees } from "src/core/Models/Core.interface";

// question input
export function onQuestionInputValueChange(
  questionDTO: DTOs.QuestionDTO,
  evt: any,
  onChange: Function
): void {
  const newQuestionDTO: DTOs.QuestionDTO = {
    ...questionDTO,
    data: {
      ...questionDTO.data,
      userValue: evt.target.value,
    },
  };
  onChange && onChange(newQuestionDTO);
}

export function handleEventTargetValue(
  questionDTO: DTOs.QuestionDTO,
  evt: any
): void {
  const min = questionDTO.data.inputConstraints.min;
  const max = questionDTO.data.inputConstraints.max;
  const maxLength = questionDTO.data.inputConstraints.maxLength;

  if (evt?.target && min !== undefined && +evt.target.value < min) {
    if (min !== 0) {
      evt.target.value = "";
    } else {
      evt.target.value = min;
    }
  }
  if (
    evt?.target &&
    maxLength !== undefined &&
    evt.target.value.length > maxLength
  ) {
    evt.target.value = evt.target.value.substring(0, maxLength);
  }

  if (evt?.target && max !== undefined && +evt.target.value > max) {
    evt.target.value = max;
  }
}
// end question input

export function handleValidFromDateChange(state: CoreInterfaces.AppState) {
  const activeService = state.services.find(
    (service) => service.data.code === Constants.ServiceCode.GeneralInformation
  );

  const activeQuestion = activeService.data.questions.find((question) => {
    return (
      (question.data.code === Constants.GeneralInformationQuestion.Q0016 ||
        question.data.code === Constants.GeneralInformationQuestion.Q0030 ||
        question.data.code === Constants.GeneralInformationQuestion.Q0031) &&
      question.state.isShown
    );
  });

  if (!activeQuestion) return state;

  let validFromDate = null;
  let validUntilDate = null;
  if (
    activeQuestion.data.code === Constants.GeneralInformationQuestion.Q0016 ||
    activeQuestion.data.code === Constants.GeneralInformationQuestion.Q0030
  ) {
    const date = activeQuestion.data.userValue as Date;
    if (isValidDate(date)) {
      if (
        activeQuestion.data.code === Constants.GeneralInformationQuestion.Q0016
      ) {
        if (date.getDay() !== 1) {
          date.setDate(1);
        }
      }
      validFromDate = date;
    }
  } else {
    const [startDate, endDate] = activeQuestion.data.userValue as Date[];
    validFromDate = isValidDate(startDate) ? startDate : null;
    validUntilDate = isValidDate(endDate) ? endDate : null;
  }

  return {
    ...state,
    currentConfiguration: {
      ...state.currentConfiguration,
      validFromDate,
      validUntilDate,
    },
    areUnsavedChanges: true,
    unsavedChangesTimestamp: Date.now(),
  };
}

export function getQuestionValue(questionDTO: DTOs.QuestionDTO): any {
  return questionDTO.data.userValue !== "" &&
    questionDTO.data.userValue !== Constants.HelpfulConstants.PleaseSelect
    ? questionDTO.data.userValue
    : questionDTO.data.defaultValue;
}

export function questionHasDefaultAnswer(question: DTOs.QuestionDTO): boolean {
  return (
    !(
      !!question.data.defaultValue &&
      question.data.defaultValue !== Constants.HelpfulConstants.PleaseSelect &&
      question.data.userValue !== question.data.defaultValue &&
      question.data.userValue !== Constants.HelpfulConstants.PleaseSelect
    ) ||
    (question.data.inputType === Constants.QuestionInputType.RichTextEditor &&
      !(
        t(question.data.userValue as string) !==
        t(question.data.defaultValue.replace(/[\n\r]/g, ""))
      ))
  );
}

export function resolveUserValue(
  question: DTOs.QuestionDTO,
  userValue: string,
  isCheckboxChecked: boolean
): string {
  let newUserValue = "";
  const userValueInput = question.data.userValue as string;
  if (isCheckboxChecked) {
    newUserValue = userValueInput.concat("##", userValue);
  } else {
    if (userValueInput.includes(userValue)) {
      newUserValue = userValueInput.replace(`##${userValue}`, "");
    }
  }
  return newUserValue;
}

export function sanitizedLicenseValue(evt: ChangeEvent<HTMLInputElement>) {
  return evt.target.value.replace(/[^0-9]/g, "");
}

export const createEmptyLicense = (): CoreInterfaces.License => {
  const object: CoreInterfaces.License = {
    LicenseName: "",
    PricePerMonth: null,
    NumberOfUnits: null,
    Uuid: uuid(),
  };
  return object;
};

export const createEmptyContactPerson = (
  markedAsSigned: boolean
): CoreInterfaces.ContactPersonUserDetails => {
  const object: CoreInterfaces.ContactPersonUserDetails = {
    ContactPersonId: null,
    PersonalNumber: null,
    IsSigned: markedAsSigned,
    Uuid: uuid(),
  };
  return object;
};

export const createEmptyClient = (
  globalState: AppState,
  isDataRetriveFromUpsales: boolean
): CoreInterfaces.ClientDetails => {
  const object: CoreInterfaces.ClientDetails = {
    ClientType: Constants.HelpfulConstants.PleaseSelect,
    ClientName: "",
    StreetAdress: "",
    Uuid: uuid(),
    ClientOrgNr: "",
    Address: "",
    Signees: [{ Name: "", Email: "" }],
    GTSignee: "",
  };
  if (isDataRetriveFromUpsales) {
    object.ClientName = globalState.remoteData?.companyDetails?.Name;
    object.ClientOrgNr = globalState.remoteData?.companyDetails?.OrgNr;
    object.StreetAdress =
      globalState.remoteData?.companyDetails?.Addresses[0].StreetAddress;
    object.Address =
      globalState.remoteData?.companyDetails?.Addresses[0].ZipCode +
      ", " +
      globalState.remoteData?.companyDetails?.Addresses[0].City;
  }
  return object;
};

export function isOptionChecked(
  question: DTOs.QuestionDTO,
  key: string
): boolean {
  if ((question.data.userValue as string).includes(key)) {
    return true;
  }
  return false;
}

export function populateCheckboxes(arrayToMap: Array<string>): string {
  let mapArrayToString = "";
  arrayToMap.forEach((element) => {
    mapArrayToString = mapArrayToString.concat("##", `Options.${element}`);
  });
  return mapArrayToString;
}

export function mapArrayToBoolean(
  question: DTOs.QuestionDTO,
  arrayToMap: Array<string>
): boolean {
  let iteration = 0;
  arrayToMap.forEach((element) => {
    if ((question.data.userValue as string).includes(element)) {
      iteration++;
    }
  });
  return iteration === arrayToMap.length;
}

export function mapQuestionCodesToQuestionDTOs(
  serviceDTO: DTOs.ServiceDTO,
  questionCodes: Array<Constants.QuestionCode>
): CoreInterfaces.QuestionMap<DTOs.QuestionDTO> {
  const object: { [key in Constants.QuestionCode]?: DTOs.QuestionDTO } = {};
  for (const questionDTO of serviceDTO.data.questions) {
    if (questionCodes.includes(questionDTO.data.code)) {
      object[questionDTO.data.code] = questionDTO;
    }
  }
  return object;
}

export function fetchTitleFromSelectedOption(
  questionDTO: DTOs.QuestionDTO
): string {
  const titleText =
    !!questionDTO &&
    questionDTO.data.userValue !== Constants.HelpfulConstants.PleaseSelect
      ? t(`Options.${questionDTO.data.userValue}`)
      : "";
  return titleText;
}

export function isLicenseDataFilled(question: DTOs.QuestionDTO) {
  const isLicenseQuestionFilled =
    Array.isArray(question.data.userValue) &&
    (question.data.userValue as CoreInterfaces.License[]).every((value) => {
      return (
        value.LicenseName !== "" &&
        value.NumberOfUnits !== null &&
        value.NumberOfUnits !== "" &&
        value.PricePerMonth !== null &&
        value.PricePerMonth !== ""
      );
    });
  return isLicenseQuestionFilled;
}

export function isContactPersonDataFilled(question: DTOs.QuestionDTO) {
  const isContactPersonQuestionFilled =
    Array.isArray(question.data.userValue) &&
    (
      question.data.userValue as CoreInterfaces.ContactPersonUserDetails[]
    ).every((value) => {
      return (
        !!value.ContactPersonId &&
        value.ContactPersonId !== null &&
        value.PersonalNumber !== null &&
        value.PersonalNumber.length === 11
      );
    }) &&
    (question.data.userValue as CoreInterfaces.ContactPersonUserDetails[]).some(
      (cp) => cp.IsSigned === true
    );
  return isContactPersonQuestionFilled;
}

export function isClientDetailsDataFilled(question: DTOs.QuestionDTO) {
  const clients = question.data.userValue as CoreInterfaces.ClientDetails[];
  let privatePersonsCount = 0;
  let hasCompany = false;
  if (Array.isArray(question.data.userValue)) {
    privatePersonsCount = clients.filter(
      (client) => client.ClientType === Constants.ClientType.PrivatePerson
    ).length;
    hasCompany = clients.some(
      (client) => client.ClientType === Constants.ClientType.Company
    );
  }
  const isClientDetailsQuestionFilled =
    Array.isArray(question.data.userValue) &&
    clients.every((value) => {
      return (
        !!value.ClientName &&
        value.ClientName !== null &&
        value.ClientOrgNr !== null &&
        value.ClientOrgNr !== "" &&
        value.ClientType.toString() !==
          Constants.HelpfulConstants.PleaseSelect &&
        value.ClientType.toString() !== "" &&
        ((value.ClientType === Constants.ClientType.Company &&
          value.Signees.every((signee: ClientSignees) => signee.Name !== "")) ||
          value.ClientType === Constants.ClientType.PrivatePerson)
      );
    }) &&
    (hasCompany ||
      privatePersonsCount === 1 ||
      (privatePersonsCount > 1 && hasCompany));
  return isClientDetailsQuestionFilled;
}

export function isSelectableQuestionDataFilled(question: DTOs.QuestionDTO) {
  const isSelectableQuestionFilled =
    Array.isArray(question.data.userValue) &&
    (question.data.userValue as unknown as string[]).every((value) => {
      return (
        !!value &&
        value !== "" &&
        value !== Constants.HelpfulConstants.PleaseSelect
      );
    });
  return isSelectableQuestionFilled;
}

export function isRangeNumberQuestionDataFilled(question: DTOs.QuestionDTO) {
  const userValue = question.data.userValue as unknown as string[];
  if (Array.isArray(userValue)) {
    const value =
      userValue[0] !== "" &&
      userValue[1] !== "" &&
      parseInt(userValue[0]) < parseInt(userValue[1]);
    return value;
  }
  return false;
}

export function formatStringToPersonalNumber(input: string) {
  const cleanedInput = input.replace(/\D/g, "");

  const maxLength = 10;
  const truncatedInput = cleanedInput.slice(0, maxLength);

  const formattedString = truncatedInput.replace(/(\d{6})(\d{4})/, "$1-$2");

  return formattedString;
}

export function areAllFieldsCompleted(question: DTOs.QuestionDTO) {
  const isLicenseQuestion =
    question.data.inputType === Constants.QuestionInputType.License;
  let isLicenseQuestionFilled = false;
  if (isLicenseQuestion) {
    isLicenseQuestionFilled = isLicenseDataFilled(question);
  }

  const isContactPersonUserDetails =
    question.data.inputType ===
    Constants.QuestionInputType.ContactPersonUserDetails;
  let isContactPersonUserDetailsQuestionFilled = false;
  if (isContactPersonUserDetails) {
    isContactPersonUserDetailsQuestionFilled =
      isContactPersonDataFilled(question) &&
      checkContactPersonUserDetailsFilled(question);
  }

  const isMultipleSelectQuestion =
    question.data.inputType === Constants.QuestionInputType.MultipleSelect;
  let isMultipleSelectDataFilled = false;
  if (isMultipleSelectQuestion) {
    isMultipleSelectDataFilled =
      isSelectableQuestionDataFilled(question) &&
      checkSelectableQuestionFilled(question);
  }
  const isRangeDateQuestion =
    question.data.inputType === Constants.QuestionInputType.FullDate;
  let isRangeDateQuestionFilled = false;
  if (isRangeDateQuestion) {
    const userValue = question.data.userValue as Date[];
    if (Array.isArray(userValue)) {
      const isOrderedCronologically = userValue[0] < userValue[1];
      isRangeDateQuestionFilled =
        isOrderedCronologically &&
        userValue.every((value) => value !== null) &&
        calculateYearsDifference(userValue[0], userValue[1]) < 5;
    }
  }

  const isClientDetailsQuestion =
    question.data.inputType === Constants.QuestionInputType.ClientDetails;
  let isClientDetailsQuestionFilled = false;
  if (isClientDetailsQuestion) {
    isClientDetailsQuestionFilled = isClientDetailsDataFilled(question);
  }

  const isRangeNumberQuestion =
    question.data.inputType === Constants.QuestionInputType.RangeNumber;
  let isRangeNumberQuestionFilled = false;
  if (isRangeNumberQuestion) {
    isRangeNumberQuestionFilled = isRangeNumberQuestionDataFilled(question);
  }

  const hasEmailFormatQuestion =
    question.data.inputType === Constants.QuestionInputType.Text &&
    question.data.inputConstraints?.format === Constants.EmailRegex;
  let hasEmailFormatQuestionFilled = false;
  if (hasEmailFormatQuestion) {
    hasEmailFormatQuestionFilled = Constants.EmailRegex.test(
      question.data.userValue as string
    );
  }

  const isDateQuestion =
    question.data.inputType === Constants.QuestionInputType.Date;
  let isDateQuestionFilled = false;
  if (isDateQuestion) {
    const date = new Date(question.data.userValue as string);
    isDateQuestionFilled =
      question.data.userValue !== "" &&
      !isNaN(date.getTime()) &&
      date.getFullYear() >= Constants.MIN_YEAR &&
      date.getFullYear() <= Constants.MAX_YEAR &&
      (!question.data.inputConstraints.allowFutureDate
        ? new Date(question.data.userValue as string) <= new Date()
        : true);
  }

  return (
    (!isLicenseQuestion || (isLicenseQuestion && isLicenseQuestionFilled)) &&
    (!isContactPersonUserDetails ||
      (isContactPersonUserDetails &&
        isContactPersonUserDetailsQuestionFilled)) &&
    (!isMultipleSelectQuestion ||
      (isMultipleSelectQuestion && isMultipleSelectDataFilled)) &&
    (!isRangeDateQuestion ||
      (isRangeDateQuestion && isRangeDateQuestionFilled)) &&
    (!isRangeNumberQuestion ||
      (isRangeNumberQuestion && isRangeNumberQuestionFilled)) &&
    (!isClientDetailsQuestion ||
      (isClientDetailsQuestion && isClientDetailsQuestionFilled)) &&
    (!isDateQuestion || (isDateQuestion && isDateQuestionFilled)) &&
    (!hasEmailFormatQuestion ||
      (hasEmailFormatQuestion && hasEmailFormatQuestionFilled))
  );
}

export function checkSelectableQuestionFilled(
  question: DTOs.QuestionDTO
): boolean {
  if (
    Array.isArray(question.data.userValue) &&
    question.data.userValue.length > 0
  ) {
    const options = question.data.options;
    const userValues = question.data.userValue as string[];
    return userValues.every((userValue) =>
      options.find((option) => option.key == userValue)
    );
  }
  return false;
}

export function checkContactPersonUserDetailsFilled(
  question: DTOs.QuestionDTO
): boolean {
  if (
    Array.isArray(question.data.userValue) &&
    question.data.userValue.length > 0
  ) {
    const options = question.data.options;
    const userValues = question.data
      .userValue as CoreInterfaces.ContactPersonUserDetails[];
    return userValues.every((userValue) =>
      options.find((option) => option.key == userValue.ContactPersonId)
    );
  }
  return false;
}
