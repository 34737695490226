import * as Constants from "./../../core/Constants";
import { CoreInterfaces, DTOs } from "src/core/Models";

export const questionValueProcessors: {
  [code in Constants.QuestionCode]?: Function;
} = {
  [Constants.GeneralInformationQuestion.Q0018]: (
    questionDTO: DTOs.QuestionDTO,
    questionConfig: CoreInterfaces.QuestionConfigurationWrite
  ) => {
    try {
      if (
        questionConfig.UserInput.Answer ===
        Constants.HelpfulConstants.PleaseSelect
      ) {
        questionDTO.data.userValue = [] as number[];
      } else {
        const parsedAnswer = JSON.parse(questionConfig.UserInput.Answer);
        let usedAnswer: Array<number> = [];
        if (Number(parsedAnswer) > 0) {
          usedAnswer = [Number(parsedAnswer)];
        } else if (Array.isArray(parsedAnswer)) {
          usedAnswer = parsedAnswer;
        }
        questionDTO.data.userValue = usedAnswer;
      }
    } catch (error) {
      console.error("Error found while parsing the value: ", error);
      questionDTO.data.userValue = [] as number[];
    }
  },
  [Constants.GeneralInformationQuestion.QT105]: (
    questionDTO: DTOs.QuestionDTO,
    questionConfig: CoreInterfaces.QuestionConfigurationWrite
  ) => {
    try {
      if (
        questionConfig.UserInput.Answer ===
        Constants.HelpfulConstants.PleaseSelect
      ) {
        questionDTO.data.userValue = [] as number[];
      } else {
        const parsedAnswer = JSON.parse(questionConfig.UserInput.Answer);
        let usedAnswer: Array<number> = [];
        if (Number(parsedAnswer) > 0) {
          usedAnswer = [Number(parsedAnswer)];
        } else if (Array.isArray(parsedAnswer)) {
          usedAnswer = parsedAnswer;
        }
        questionDTO.data.userValue = usedAnswer;
      }
    } catch (error) {
      console.error("Error found while parsing the value: ", error);
      questionDTO.data.userValue = [] as number[];
    }
  },
  [Constants.GeneralInformationQuestion.Q0025]: (
    questionDTO: DTOs.QuestionDTO,
    questionConfig: CoreInterfaces.QuestionConfigurationWrite
  ) => {
    try {
      const parsedAnswer = JSON.parse(questionConfig.UserInput.Answer);
      let usedAnswer: Array<string> = ["", ""];
      if (Array.isArray(parsedAnswer) && parsedAnswer.length === 2) {
        usedAnswer = parsedAnswer;
      }
      questionDTO.data.userValue = usedAnswer;
    } catch (error) {
      console.error("Error found while parsing the value:  ", error);
      questionDTO.data.userValue = ["", ""];
    }
  },

  [Constants.GeneralInformationQuestion.Q0031]: (
    questionDTO: DTOs.QuestionDTO,
    questionConfig: CoreInterfaces.QuestionConfigurationWrite
  ) => {
    try {
      const parsedAnswer = JSON.parse(questionConfig.UserInput.Answer);
      let usedAnswer: Array<Date> = [null, null];
      if (Array.isArray(parsedAnswer) && parsedAnswer.length === 2) {
        usedAnswer = parsedAnswer.map((value) => {
          if (!!value) {
            return new Date(value);
          }
          return null;
        });
      }
      questionDTO.data.userValue = usedAnswer;
    } catch (error) {
      console.error("Error found while parsing the value: ", error);
      questionDTO.data.userValue = [null, null] as Array<Date>;
    }
  },
};
